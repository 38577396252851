import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import List from "../views/List.vue";
import Admin from "../views/Admin.vue";
import New from "../views/New.vue";
import Pix from "../views/Pix.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/rsvp/:id',
    name: 'RSVP',
    component: Home
  },
  {
    path: '/list/:id',
    name: 'LIST',
    component: List
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/new/:id',
    name: 'New',
    component: New
  },
  {
    path: '/pix/:id',
    name: 'Pix',
    component: Pix
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
