<template>
  <v-container>
    <v-row>
      <v-col><present :id="event.id" :term="event.term" :confirmType="event.confirmType" :noGuest="event.noGuest" :noAge="event.noAge" :termLocal="event.termlocal" :textCard1="event.textCard1"></present></v-col>
      <v-col><absent :id="event.id" :confirmType="event.confirmType" :termLocal="event.termlocal"></absent></v-col>
      <!--<v-col><gift :id="event.id"></gift></v-col>-->
    </v-row>  
  </v-container>
</template>
<script>
  import Absent from "../components/Absent.vue";
  import Present from "../components/Present.vue";
  //import Gift from "../components/Gift.vue";

  export default {
    components: { Present, Absent },
    //components: { Present, Absent, Gift },
    props: {
      event: Object,
    }
  };
</script>
<style>
  .subtitle {
    font-family: "Rancho", sans-serif;
    color: #fff;
    text-shadow: 2px 2px 2px black;
    font-size: 3em;
  }
</style>
