<template>
  <v-app fill-height>
    <v-app-bar fill-height color="#fe9" prominent app src="https://source.unsplash.com/random/?yellow background">
      <v-spacer></v-spacer>
      <v-img class="logo mb-4" max-width="35vh" src="https://cdn.awsli.com.br/400x300/2277/2277447/logo/2a6de7cf59.png"></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-tabs v-model="tab" background-color="blue-grey darken-3" centered dark icons-and-text>
        <v-tab v-if="this.$route.name == 'New'" :to="'/new/' + event.id" :disabled="!eventKey">
          Novo Evento
          <v-icon>mdi-account-multiple</v-icon>
        </v-tab>
        <v-tab v-else-if="this.$route.name == 'RSVP'" :to="'/rsvp/' + event.id" :disabled="!eventKey">
          Confirmação de Presença
          <v-icon>mdi-check</v-icon>
        </v-tab>
        <v-tab v-else-if="this.$route.name == 'LIST'" :to="'/list/' + event.id" :disabled="!eventKey">
          Lista de Confirmados & Convite
          <v-icon>mdi-account-multiple</v-icon>
        </v-tab>
      </v-tabs>
      <template v-if="!loading">
        <v-container fluid v-if="(this.$route.name == 'RSVP' || this.$route.name == 'LIST') && !event.disabled">
          <titlebar :title="event.title"></titlebar>
          <template v-if="this.event.disabled == true">
            <p class="d2">Confirmações finalizadas!</p>
          </template>
          <template v-else>
            <router-view :event="event"></router-view>
          </template>
        </v-container>
        <v-container fluid v-else-if="this.$route.name == 'New'">
          <router-view :event="event"></router-view>
        </v-container>
        <v-container fluid v-else-if="this.$route.name == 'Admin'">
          <titlebar title="Admin"></titlebar>
          <router-view></router-view>
        </v-container>
        <v-container v-else>
          <v-alert border="bottom" colored-border elevation="2" class="mt-10 pa-10">
            <h3>Animavite</h3>
            <p>&nbsp;</p>
            <p>Conheça nossos Exclusivos Cartões Virtuais Animados e Interativos!</p>
            <p>
              <v-icon>mdi-web</v-icon>
              <a href="https://www.animavite.com/">www.animavite.com</a>
            </p>
          </v-alert>
        </v-container>
      </template>
      <template v-else>
        <v-container>
          <v-progress-linear class="ma-10 pa-5" indeterminate color="yellow darken-1">Carregando</v-progress-linear>
        </v-container>
      </template>
    </v-main>
    <v-footer dark padless>
      <v-card class="flex" style="height: fit-content" flat tile>
        <v-card-title class="grey lighten-1" style="max-height: 2.5em">
          <strong class="subheading" style="font-size: 0.75em; color: #777">Animavite: Convites animados e interativos</strong>
          <v-spacer></v-spacer>
          <v-btn v-for="icon in socialIcons" :key="icon.name" class="mx-4" light icon>
            <v-icon size="24px" @click="icon.url">{{ icon.name }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-2 white--text text-center"
          >{{ new Date().getFullYear() }} — <strong><a id="FooterLink" target="_blank" href="https://www.animavite.com">www.animavite.com</a></strong></v-card-text
        >
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
  import Titlebar from "./components/Titlebar.vue";

  export default {
    components: { Titlebar },
    data: () => ({
      event: {
        confirmType: 1,
        noGuest: false,
        noAge: true,
        disabled: false,
      },
      eventKey: false,
      socialIcons: [
        { name: "mdi-facebook", url: "https://www.facebook.com/Animavite" },
        { name: "mdi-instagram", url: "https://www.instagram.com/animavite/" },
        { name: "mdi-youtube", url: "https://www.youtube.com/channel/UChH8RWlASgw6TQZ2VNiJRHw" },
      ],
      tab: null,
      loading: true,
    }),
    created() {
      this.getEventKey();
      if(this.loading) this.loading = false;
    },
    methods: {
      getEventKey() {
        var id = this.$route.params.id;
        if (id)
          this.$http.get("events/-" + id + ".json").then((res) => {
            if (res.data) {
              this.event = res.data;
              this.eventKey = true;
              this.loading = false;
            } else this.eventKey = false;
          });
      },
    },
  };
</script>
<style>
  .logo {
    text-align: center;
    margin: auto;
    margin-top: 1vh;
    position: relative;
    display: block;
  }
  @import url("https://fonts.googleapis.com/css?family=Rancho");
  h2,
  h3,
  h4 {
    font-family: "Rancho", sans-serif;
    color: #444;
    font-size: 2em;
    font-weight: 300;
  }
  h4 {
    font-size: 1.8em;
  }
  .title2 {
    font-family: "Rancho", sans-serif;
  }
  a:link,
  a:visited {
    text-decoration: none;
    color: white;
  }
  a:hover {
    color: yellow;
    text-decoration: underline;
  }
  .subtitle {
    font-family: "Rancho", sans-serif;
    color: #fff;
    text-shadow: 2px 2px 2px black;
    font-size: 3em;
  }
  a#FooterLink {
    color: orange;
  }
  .d1 {
    text-align: center;
  }
  .d2 {
    text-align: center;
    margin: 1vh;
    font-size: 1.5rem;
    padding: 5px;
    background-color: #ffffaa;
  }
</style>
