<template>
  <v-card class="mx-auto" max-width="500" min-height="360">
    <v-img
      class="white--text align-end"
      height="200px"
      src="https://source.unsplash.com/random/?midia"
    >
      <v-card-title class="subtitle">Download do Convite</v-card-title>
    </v-img>
    <v-card-subtitle class="pb-3">Link de acesso do convite</v-card-subtitle>
    <v-card-text class="text--primary"
      >Basta clicar ou apertar o botão
      abaixo para acessar seu convite!</v-card-text
    >
    <v-card-actions>
      <v-btn v-if="link" block :href="link" target="_blank" color="primary" dark>Download do convite</v-btn>
      <v-btn v-else block disabled >Indisponível</v-btn>

   </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {link: String},
  };
</script>
