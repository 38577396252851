<template>
  <v-card :max-width="this.$vuetify.breakpoint.name == 'xs' ? '90vw' : '45vw'" class="mx-auto" min-height="360">
    <v-img class="white--text align-end bw" height="200px" :src="termLocal ? 'https://confirmar.animavite.com/bg/' + termLocal : 'https://source.unsplash.com/random/?sky'">
      <v-card-title class="subtitle">Informar ausência</v-card-title>
    </v-img>
    <v-card-subtitle class="pb-3">Não poderei participar do evento!</v-card-subtitle>
    <v-card-text class="text--primary">Entendemos que nem sempre é possível participar do evento.<br />Utilize o botão abaixo para informar a sua ausência!</v-card-text>
    <v-card-actions>
      <v-dialog v-model="dialog2" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn block color="primary" dark v-bind="attrs" v-on="on">Informar</v-btn>
        </template>
        <v-card>
          <v-alert v-if="this.informed" class="darken-2" type="warning">
            <strong>A sua ausência já foi informada!</strong>
            <div>
              <small>Se mudar de ideia, você pode confirmar sua presença até a data do evento</small>
            </div>
          </v-alert>
          <v-card-title>Informar Ausência</v-card-title>
          <v-card-text>
            <v-container>
              <v-alert v-if="this.confirmType == 3" transition="scale-transition" :value="cpfError" border="left" color="red" dense dismissible elevation="4" icon="mdi-account" type="error">Preencha o CPF corretamente.</v-alert>
              <v-row>
                <v-col cols="12" v-if="this.confirmType != 2">
                  <v-text-field v-if="this.confirmType == 1" v-model="guest.cpf" @blur="retrieve()" label="Número do telefone" hide-details="auto" required v-mask="['(##) #####-####']"></v-text-field>
                  <v-text-field v-if="this.confirmType == 3" v-model="guest.cpf" @blur="retrieve()" label="CPF" hide-details="auto" required v-mask="['###.###.###-##']"></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="showName || this.confirmType == 2">
                <v-col cols="12">
                  <v-text-field v-model="guest.name" label="Nome Completo" :rules="rules" hide-details="auto" required></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="guest.name && showName">
                <v-col cols="12"
                  >Não poderei participar do evento. Desejo felicidades!
                  <v-textarea v-model="guest.msg" solo name="input-7-4" label="Mensagem opcional"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="warning" @click="dialog2 = false">Fechar </v-btn>
            <v-btn depressed color="success" @click="save()" :disabled="guest.name.length < 3">Informar</v-btn>
          </v-card-actions>
          <v-dialog v-model="thankyou" max-width="400">
            <v-img style="width: 400px; height: 400px" src="../assets/absence.png"></v-img>
          </v-dialog>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      id: String,
      confirmType: { type: Number, default: 1 },
      termLocal: String,
    },
    data: () => ({
      guest: {
        name: "",
        cpf: "",
        tel: "",
        accept: false,
        attaches: {},
        msg: "Não poderei participar do evento. Desejo felicidades!",
      },
      dialog2: false,
      thankyou: false,
      informed: false,
      rules: [(value) => !!value || "Campo obrigatório.", (value) => (value && value.length >= 3) || "Minínimo 3 caracteres"],
    }),
    methods: {
      save: function () {
        this.dialog2 = false;
        if(this.confirmType == 2) this.guest.cpf = Math.floor(Date.now() / 1000) + "";
        if (this.guest.cpf) {
          let guestId = this.guest.cpf.trim().replace(/\./g, "").replace(/-/g, "").replace("(", "").replace(")", "").replace(" ", "");
          this.$http.patch("events/-" + this.id + "/guests/" + guestId + ".json", this.guest).then(() => {
            this.$http.delete(`/events/-${this.id}/guests/${guestId}/attaches.json`);
            this.thankyou = true;
            setTimeout(() => {
              this.thankyou = false;
            }, 5000);
            this.guest.name = "";
            this.guest.cpf = "";
            this.guest.tel = "";
            this.guest.msgs = "";
            this.informed = false;
          });
        }
      },
      retrieve: function () {
        if (this.guest.cpf) {
          let guestId = this.guest.cpf.replace(/\./g, "").replace("-", "").replace("(", "").replace(")", "").replace(" ", "").trim();
          this.$http.get("events/-" + this.id + "/guests/" + guestId + ".json").then((res) => {
            if (res.data && !res.data.accept) {
              this.guest = res.data;
              this.informed = true;
            }
          });
        }
      },
      checkCPF: function () {
        if (this.guest.cpf.length != 14) return false;
        var soma = 0;
        var i;
        var resto;
        let inputCPF = this.guest.cpf.replace(/\./g, "").replace("-", "");

        if (inputCPF == "00000000000") return false;
        for (i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(inputCPF.substring(9, 10))) return false;

        soma = 0;
        for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(inputCPF.substring(10, 11))) return false;

        return true;
      },
    },
    watch: {
      guest: function () {
        if (!this.guest.cpf) this.informed = false;
      },
    },
    computed: {
      showName: function () {
        console.log(this.guest.cpf.length)
        return (this.confirmType == 3 && this.checkCPF()) || (this.confirmType == 1 && this.guest.cpf.length == 15);
      },
      cpfError: function () {
        return (this.confirmType == 3 && this.guest.cpf != "" && this.guest.cpf.length == 14 && !this.checkCPF()) || (this.confirmType == 1 && this.guest.cpf.length == 15);
      },
    },
  };
</script>
<style scoped>
  .bw {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
</style>
