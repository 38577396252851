import Vue from "vue";
import Axios from "axios";


const url1 = Axios.create({
    baseURL: 'https://animavite-online-default-rtdb.firebaseio.com/'
});

const url2 = Axios.create({
    baseURL: 'https://confirmar.animavite.com/'
});


Vue.use({
    install(Vue) {
        Vue.prototype.$http = url1
        Vue.prototype.$http2 = url2
    }
})

Vue.prototype.$http.interceptors.response.use(res => {
    let filename = window.location.hash.replace(/\//g, '').replace('#', '');
    //  let filename = window.location.pathname.replace(/\//g, '')
    if (filename == 'admin') {
        const array = []
        for (let key in res.data)
            array.push({ id: key, ...res.data[key] }) // spread
        res.data = array
    }
    return res
})