<template>
  <v-card>
    <v-card-title>Sugestões de Presentes</v-card-title>
    <v-card-text>
      <v-container>
        <v-row v-if="itens">
          <v-col :cols="!$vuetify.breakpoint.xs ? 4 : 12" v-for="(item, index) in itens" :key="index">
            <v-card class="box">
              <v-img width="100%" height="100px" :src="require(`../assets/${item.image}`)"> </v-img>
              <v-slide-group class="justify-center grey--text text--darken-3 title--center pa-2 subheading font-weight-medium"> {{ item.title }}: {{ item.description }} </v-slide-group>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed color="warning" @click="dialog = false">Fechar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    data: () => {
      return {
        itens: [
          { image: "vestidos.png", title: "Vestidos", description: "" },
          { image: "calcados.png", title: "Calçados", description: "" },
          { image: "blusas.png", title: "Blusas", description: "" },
          { image: "calcas.png", title: "Calças", description: "" },
          { image: "brinquedos.png", title: "Brinquedos", description: "" },
          { image: "maquiagens.png", title: "Make", description: "" },
          { image: "perfumes.png", title: "Perfume", description: "" },
          { image: "bebidas.png", title: "Bebidas", description: "" },
          { image: "livros.png", title: "Livros", description: "" },
          { image: "bolsas.png", title: "Bolsas", description: "" },
          { image: "joias.png", title: "Jóias", description: "" },
          { image: "pix.png", title: "Pix", description: "" },
          { image: "diversos.png", title: "Diversos", description: "" },
        ],
        boxActive: [],
      };
    },
    computed: {
      box() {
        return this.boxActive ? "boxActive" : "box";
      },
    },
  };
</script>

<style>
  .box {
    transition: opacity 0.4s ease-in-out;
    opacity: 0.8;
    filter: saturate(0);
    transition: transform 0.2s; /* Animation */
  }
  .box:hover,
  .boxActive {
    opacity: 1;
    filter: saturate(1);
    transform: scale(1.1);
  }
</style>
