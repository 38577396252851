<template>
  <v-card class="mx-auto" max-width="450" min-height="360">
    <v-img class="white--text align-end" height="200px" src="https://source.unsplash.com/random/?online">
      <v-card-title class="subtitle">Lista de Presença On-line</v-card-title>
    </v-img>
    <v-card-subtitle class="pb-3">Lista online e interativa </v-card-subtitle>
    <v-card-text class="text--primary">Acesso rápido e interativo da lista de convidados confirmados.</v-card-text>
    <v-card-actions>
      <v-dialog v-model="dialog1" persistent max-width="90vw">
        <template v-slot:activator="{ on, attrs }">
          <v-btn block color="primary" dark v-bind="attrs" v-on="on">Ver a lista</v-btn>
        </template>
        <v-card>
          <v-card-title class="white--text grey darken-1 title2" style="font-size: 2em">Lista de Presença</v-card-title>
          <v-card-text class="pa-0">
            <v-container class="pa-0 ma-0" fluid>
              <v-card>
                <v-card-title>
                  <v-card-text class="pa-2" v-if="!$vuetify.breakpoint.xs">
                    <div class="text-center">
                      <v-chip class="mr-1" label color="deep-purple accent-4" outlined>{{ guests.length }} respostas</v-chip>
                      <v-chip class="mr-1" label color="error" outlined>{{ guests.filter(({ accept }) => accept === "Ausente").length }} Ausentes</v-chip>
                      <v-chip class="mr-1" label color="primary" outlined> {{ guests.filter(({ accept }) => accept === "Confirmado").length }} Confirmados </v-chip>
                      <v-divider class="ma-1" dark />
                      <v-chip class="mr-1" label color="success" outlined> {{ total }} Confirmados + Acompanhantes </v-chip>
                      <v-chip v-if="!this.event.noAge" class="mr-1" label color="red darken-1" outlined>{{ totalChild }} Crianças</v-chip>
                      <v-chip v-if="!this.event.noAge" class="mr-1" label color="blue-grey darken-3" outlined>{{ total - totalChild }} Adolescentes/Adultos</v-chip>
                    </div>
                  </v-card-text>
                  <v-card-text v-else>
                    <div class="text-center">
                      <v-chip class="mr-1"   label color="deep-purple accent-4" outlined>{{ guests.length }} respostas</v-chip>
                      <v-chip class="mr-1" label color="error" outlined>{{ guests.filter(({ accept }) => accept === "Ausente").length }} Ausentes</v-chip>
                      <v-chip class="mr-1" label color="primary" outlined> {{ guests.filter(({ accept }) => accept === "Confirmado").length }} Confirmados </v-chip>
                      <v-chip class="mr-1" label color="success" outlined> {{ total }} Confirmados + Acompanhantes </v-chip>
                      <v-chip v-if="!this.event.noAge" class="mr-1" label color="red darken-1" outlined>{{ totalChild }} Crianças</v-chip>
                      <v-chip v-if="!this.event.noAge" class="mr-1" label color="blue-grey darken-3" outlined>{{ total - totalChild }} Adolescentes/Adultos</v-chip>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details></v-text-field>
                </v-card-title>
                <v-card-text class="text-center">
                  <v-btn class="success" :block="$vuetify.breakpoint.xs" @click="search = 'Confirmado'">Confirmados</v-btn>
                  <v-divider class="mx-2" inset vertical></v-divider>
                  <v-btn class="warning" :block="$vuetify.breakpoint.xs" dark @click="search = 'Ausente'">Ausentes</v-btn>
                  <v-divider class="mx-2" inset vertical></v-divider>
                  <v-btn class="primary" :block="$vuetify.breakpoint.xs" @click="search = ''">Todos</v-btn>
                </v-card-text>
                <v-card-title class="grey lighten-2" style="font-size: 1.2em; color: #555">Mostrando {{ search != "" ? search : "Todos" }}</v-card-title>
                <v-data-table :headers="headers" :items="this.guests" :search="search"></v-data-table>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="purple" dark @click="dialog1 = false">Fechar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: { event: Object },
    data: () => ({
      dialog1: false,
      search: "",
      headers: [
        {
          text: "Convidado",
          align: "start",
          value: "name",
        },
        { text: "Resposta", value: "accept" },
        { text: "Acompanhantes", value: "attaches" },
        { text: "Mensagem", value: "msg" },
      ],
      guests: [],
      total: 0,
      totalChild: 0,
    }),
    created() {
      const array = [];

      for (let key in this.event.guests) {
        var attaches = "";
        if (this.event.guests[key].attaches) {
          for (let key2 in this.event.guests[key].attaches) {
            let term = "";
            if (this.event.guests[key].attaches[key2].age) {
              if (!isNaN(this.event.guests[key].attaches[key2].age)) term = this.event.guests[key].attaches[key2].age > 1 ? "anos" : "ano";
              this.event.guests[key].attaches[key2].name += " (" + this.event.guests[key].attaches[key2].age + " " + term + ")";
            }
            attaches += this.event.guests[key].attaches[key2].name + ", ";
            this.total++;
          }
        }
        attaches = attaches.slice(0, -2);
        let term = "";
        if (this.event.guests[key].age) {
          if (!isNaN(this.event.guests[key].age)) term = this.event.guests[key].age > 1 ? "anos" : "ano";
          this.event.guests[key].name += " (" + this.event.guests[key].age + " " + term + ")";
        }
        if (this.event.guests[key].accept) this.total++;
        array.push({
          id: key,
          name: this.event.guests[key].name,
          msg: this.event.guests[key].msg,
          accept: this.event.guests[key].accept ? "Confirmado" : "Ausente",
          attaches: attaches,
        });
      }
      this.guests = array;
    },
    mounted() {
      if (!this.event.noAge) {
        for (let key in this.event.guests) {
          if (this.event.guests[key].age != "" && !isNaN(this.event.guests[key].age) && this.event.guests[key].age < 12) this.totalChild++;
          if (this.event.guests[key].attaches)
            this.event.guests[key].attaches.filter((a) => {
              if (a.age && !isNaN(a.age) && a.age < 12) this.totalChild++;
            });
        }
      }
    },
  };
</script>
