<template>
  <v-toolbar-title class="text-center mt-5 mb-5 text-wrap">
    <h2 v-if="title == 'Admin'">
      Sistema de controle de eventos para confirmação de presença
    </h2>
    <h2  v-else>{{ title }}</h2>
  </v-toolbar-title>
</template>

<script>
  export default {
    props: {
      title: String
    },
  };
</script>
