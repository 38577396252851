<template>
  <v-container fluid>
    <v-row v-if="logged">
      <v-col cols="12" lg="4" md="4"><weblist :event="event"></weblist></v-col>
      <v-col cols="12" lg="4" md="4"><reportlist :event="event"></reportlist></v-col>
      <v-col cols="12" lg="4" md="4"><invite :link="event.invite"></invite></v-col>
      <v-col cols="12" lg="12" md="12" class="text-center">
        <v-btn large class="error" @click="logged = false">Sair</v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-card class="mx-auto" max-width="600">
        <v-alert transition="scale-transition" :value="msgError" border="left" color="red" dense dismissible elevation="4" icon="mdi-account" type="error">Código de acesso incorreto</v-alert>
        <v-card-text>
          <v-text-field v-model="authData" label="Insira sua chave de acesso" prepend-inner-icon="mdi-key" solo id="rsvp"></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="login()"> Acessar </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
  import Reportlist from "../components/Reportlist.vue";
  import Weblist from "../components/Weblist.vue";
  import Invite from "../components/Invite.vue";
  export default {
    components: { Reportlist, Weblist, Invite },
    props: { event: Object },
    data: () => ({
      logged: false,
      authData: "",
      msgError: false,
    }),
    methods: {
      login() {
        let link = "rsvplink/-" + this.authData.trim() + ".json";
        this.$http.get(link).then((res) => {
          this.logged = res.data && res.data.id == this.event.id;
          if (this.logged) {
            this.authData = "";
            this.msgError = false;
          } else {
            this.msgError = true;
            setTimeout(() => {
              this.msgError = false;
            }, 5000);
          }
        });
      },
    },
  };
</script>
<style>
  .subtitle {
    font-family: "Rancho", sans-serif;
    color: #fff;
    text-shadow: 2px 2px 2px black;
    font-size: 3em;
    white-space: nowrap;
  }
  #rsvp {
    text-align: center;
    font-size: 1.5em;
  }
</style>
