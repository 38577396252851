var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500","min-height":"360"}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"height":"200px","src":"https://source.unsplash.com/random/?pdf"}},[_c('v-card-title',{staticClass:"subtitle"},[_vm._v("Lista de Confirmados em PDF")])],1),_c('v-card-subtitle',{staticClass:"pb-3"},[_vm._v("Relatório em PDF")]),_c('v-card-text',{staticClass:"text--primary"},[_vm._v("Esta opção é recomendada para impressão do documento em papel."),_c('br')]),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","color":"primary","dark":""},on:{"click":function($event){return _vm.generateReport()}}},[_vm._v(" Download da Lista ")])],1),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":"Animavite - Relação de convidados","pdf-quality":2,"manual-pagination":false,"pdf-format":"A4","pdf-orientation":"portrait","pdf-content-width":"800px"},on:{"progress":function($event){return _vm.onProgress($event)},"hasStartedGeneration":function($event){return _vm.hasStartedGeneration()},"hasGenerated":function($event){return _vm.hasGenerated($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"pdfTop"},[_c('img',{staticClass:"pdfLogo",attrs:{"src":require("../assets/logo.png")}}),_c('div',[_vm._v("www.animavite.com")])]),_c('h1',{staticClass:"pdf"},[_vm._v(_vm._s(_vm.event.title))]),_c('h4',{staticClass:"pdf"},[_vm._v("Confirmação de Presença")]),_c('table',{staticClass:"total pdf"},[_c('tr',[_c('td',{staticClass:"pdf",staticStyle:{"width":"30%"}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Total de confirmados: "+_vm._s(_vm.guests.filter(function (ref) {
	var accept = ref.accept;

	return accept === "Confirmado";
}) .length)+" ")],1),_c('td',{staticStyle:{"width":"40%"}},[_c('v-icon',[_vm._v("mdi-account-multiple")]),_vm._v(" Total confirmados & acompanhantes: "+_vm._s(this.totalPearson)+" ")],1),_c('td',{staticStyle:{"width":"30%"}},[_c('v-icon',[_vm._v("mdi-cancel")]),_vm._v(" Total de ausentes: "+_vm._s(_vm.guests.filter(function (ref) {
	var accept = ref.accept;

	return accept === "Ausente";
}).length)+" ")],1)])]),_c('table',{staticClass:"pdf"},[_c('tr',[_c('th'),_c('th',[_vm._v("Nome")]),_c('th',[_vm._v("Resposta")]),_c('th',[_vm._v("Acompanhantes")]),_c('th',[_vm._v("Mensagem")])]),_vm._l((_vm.guests),function(guest,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1)+".")]),_c('td',[_vm._v(_vm._s(guest.name))]),_c('td',[_vm._v(_vm._s(guest.accept))]),_c('td',[_vm._v(_vm._s(guest.attaches))]),_c('td',[_vm._v(_vm._s(guest.msg))])])})],2),_c('div',{attrs:{"id":"footer"}},[_vm._v("Relatório gerado em "+_vm._s(_vm.dateTime()))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }