<template>
  <v-card class="mx-auto" max-width="500" min-height="360">
    <v-img
      class="white--text align-end"
      height="200px"
      src="https://source.unsplash.com/random/?pdf"
    >
      <v-card-title class="subtitle">Lista de Confirmados em PDF</v-card-title>
    </v-img>
    <v-card-subtitle class="pb-3"
      >Relatório em PDF</v-card-subtitle
    >
    <v-card-text class="text--primary"
      >Esta opção é recomendada para impressão do documento em papel.<br />
      </v-card-text
    >
    <v-card-actions>
      <v-btn block color="primary" dark @click="generateReport()">
        Download da Lista
      </v-btn>
    </v-card-actions>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Animavite - Relação de convidados"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="A4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="pdfTop">
          <img class="pdfLogo" src="../assets/logo.png" />
          <div>www.animavite.com</div>
        </div>
        <h1 class="pdf">{{event.title}}</h1>
        <h4 class="pdf">Confirmação de Presença</h4>
        <table class="total pdf">
          <tr>
            <td class="pdf" style="width: 30%">
              <v-icon>mdi-check</v-icon>
              Total de confirmados:
              {{
                guests.filter(({ accept }) => accept === "Confirmado")
                  .length
              }}
            </td>
             <td style="width: 40%">
              <v-icon>mdi-account-multiple</v-icon>
              Total confirmados & acompanhantes:
              {{
                this.totalPearson
              }}
            </td>
            <td style="width: 30%">
              <v-icon>mdi-cancel</v-icon>
              Total de ausentes:
              {{
                guests.filter(({ accept }) => accept === "Ausente").length
              }}
            </td>
          </tr>
        </table>
        <table class="pdf">
          <tr>
            <th></th>
            <th>Nome</th>
            <th>Resposta</th>
            <th>Acompanhantes</th>
            <th>Mensagem</th>
          </tr>
          <tr v-for="(guest, index) in guests" :key="index">
            <td>{{ index + 1 }}.</td>
            <td>{{ guest.name }}</td>
            <td>{{ guest.accept }}</td>
            <td>{{ guest.attaches }}</td>
            <td>{{ guest.msg }}</td>
          </tr>
        </table>
        <div id="footer">Relatório gerado em {{ dateTime() }}</div>
      </section>
    </vue-html2pdf>
  </v-card>
</template>

<script>
  import VueHtml2pdf from "vue-html2pdf";
  export default {
    props: {event: Object},
    data: () => ({
      guests : [],
      totalPearson : 0
    }),
    methods: {
      /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
      generateReport() {
        this.updateData();
        this.$refs.html2Pdf.generatePdf();
      },
      dateTime() {
        const today = new Date();
        const date =
          today.getDate() +
          "/" +
          (today.getMonth() + 1) +
          "/" +
          today.getFullYear();
        const time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        const dateTime = date + " às " + time;
        return dateTime;
      },
      updateData(){
        const array = []
        for (let chave in this.event.guests) {
            let attaches = '';
            let total = 1 
            if(this.event.guests[chave].attaches){
                total += this.event.guests[chave].attaches.length
                for(let chave2 in this.event.guests[chave].attaches)
                 attaches += this.event.guests[chave].attaches[chave2].name + ', '
                attaches = attaches.slice(0,-2)
            }
            array.push({
                id: chave,
                name: this.event.guests[chave].name,
                msg:  this.event.guests[chave].msg,      
                accept:  (this.event.guests[chave].accept)?'Confirmado':'Ausente', 
                attaches: attaches,
           })
           this.totalPearson += total
        }   
        this.guests = array
    }
    },
    components: {
      VueHtml2pdf,
    }
  };
</script>
<style>
  @import url("https://fonts.googleapis.com/css?family=Rancho");
  .pdfLogo {
    max-width: 200px;
    margin-top: -5px;
  }
  .pdfTop {
    position: relative;
    text-align: center;
    padding: 2px;
    margin: 0;
    color: white;
    /*background-image: linear-gradient(to bottom, #aaa, #fff);*/
    background-color: #364650;
    border-top: 5px solid #ccc;
    border-bottom: 5px solid #222;
    margin-bottom: 20px;
  }

  h1.pdf {
    text-align: center;
    font-size: 3em;
    font-family: "Rancho", sans-serif;
    font-weight: normal;
  }
  h4.pdf {
    text-align: center;
    font-style: italic;
  }
  table.pdf {
    width: 95%;
    margin: auto;
    border: 2px solid #567;
    padding: 10px;
    border-bottom: 5px solid #567;
  }
  table.total {
    border-bottom: 1px solid #567;
    width: 100%;
    border: 0;
    margin-top: 20px;
  }
  table.pdf tr {
    padding: 10px;
    border-bottom: 1px solid #567;
  }
  table.pdf td,
  table.pdf th {
    padding: 5px;
    text-align: left;
    font-size: 0.9em;
  }
  div#footer {
    font-size: 0.8em;
    text-align: right;
    width: 100%;
    display: block;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding:30px;
  }
</style>
