<template>
  <v-container fluid>
    <v-card v-if="!loggedAdmin" class="mx-auto" max-width="600">
      <v-card-text>
        <v-alert transition="scale-transition" :value="msgError" border="left" color="red" dense dismissible elevation="4" icon="mdi-account" type="error">Código de acesso incorreto</v-alert>
        <v-text-field v-model="authData" label="Código de acesso" prepend-inner-icon="mdi-key" solo></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="login()"> Acessar </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-data-table v-else :headers="headers" :items-per-page="-1" :items="events" class="elevation-3" :footer-props="{ itemsPerPageText: 'Registros por página' }" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
      <template #[`item.text`]="{ value }">
        <div>
          <v-btn small dark class="primary" @click="copy(value)">Copiar</v-btn>
        </div>
      </template>
      <template #[`item.urlRSVP`]="{ value }">
        <div>
          <v-btn small dark class="warning" @click="copy(value)">Copiar</v-btn>
        </div>
      </template>
      <template #[`item.urlInvite`]="{ value }">
        <div>
          <v-btn small dark class="success" @click="copy(value)">Copiar</v-btn>
        </div>
      </template>
      <template #[`item.invite`]="{ value }">
        <div>
          <v-btn v-if="value" small dark class="purple" :href="value" target="_blank">Download</v-btn>
          <v-btn v-else disabled small>Download</v-btn>
        </div>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Gerenciamento de Eventos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1250px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Novo evento </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="editedItem.order" label="Número do Pedido" prepend-inner-icon="mdi-clipboard-list"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="editedItem.title" label="Título do Evento" prepend-inner-icon="mdi-party-popper"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="editedItem.name" label="Responsável" prepend-inner-icon="mdi-account"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="editedItem.email" label="Email ou Telefone" prepend-inner-icon="mdi-mail"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="editedItem.key && editedItem.urlRSVP">
                    <v-col v-if="editedItem.key">
                      <v-text-field v-model="editedItem.key" label="Chave de acesso" prepend-inner-icon="mdi-key" hide-details> </v-text-field>
                    </v-col>
                    <v-col>
                      <v-file-input v-model="inviteFile" label="Enviar o PDF do Convite" truncate-length="-1" accept="application/pdf"></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row v-if="editedItem.urlRSVP != '' && editedItem.urlInvite != ''">
                    <v-col>
                      <v-text-field v-model="editedItem.urlRSVP" label="URL Confirmação (gerado automaticamente)" prepend-inner-icon="mdi-check"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="editedItem.urlInvite" label="URL Organização do evento (gerado automaticamente)" prepend-inner-icon="mdi-web"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select v-model="editedItem.confirmType" :items="confirmTypes" label="Tipo de Confirmação" prepend-inner-icon="mdi-account-box"></v-select>
                    </v-col>
                    <v-col>
                      <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="editedItem.date" label="Data do evento" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker locale="pt-br" v-model="editedItem.date" @input="menu2 = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col><v-checkbox v-model="editedItem.noAge" :label="`Desabilitar idade? ${textNoAge}`"></v-checkbox></v-col>
                    <v-col>
                      <v-file-input v-model="bannerFile" label="Banner Confirmação" accept="image/*"></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col><v-checkbox v-model="editedItem.noGuest" :label="`Desabilitar Acompanhantes? ${textNoGuest}`"></v-checkbox></v-col>
                    <v-col><v-text-field v-model="editedItem.textCard1" label="Texto Alternativo na Confirmação" prepend-inner-icon="mdi-text"></v-text-field> </v-col>
                  </v-row>
                  <v-row>
                    <v-col><v-checkbox v-model="editedItem.disabled" label="Desabilitar Página"></v-checkbox></v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error darken-1" @click="close"> Fechar </v-btn>
                <v-btn color="success darken-1" @click="save"> Salvar </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"> Remover o evento {{ editedItem.title }}?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="removeDB">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-card-actions v-if="loggedAdmin" class="pa-8">
      <v-spacer></v-spacer>
      <v-btn class="error ml-2" @click="loggedAdmin = false">
        <v-icon left>mdi-close-circle</v-icon>
        Sair
      </v-btn>
      <v-btn color="grey darken-2" dark @click="loadDB">
        <v-icon left>mdi-reload</v-icon>
        Recarregar
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-container>
</template>
<script>
  export default {
    data: () => ({
      bannerFile: { name: "", size: null },
      inviteFile: { name: "", size: null },
      dialog: false,
      dialogDelete: false,
      loggedAdmin: false,
      authData: "",
      msgError: false,
      sortBy: "order",
      sortDesc: true,
      confirmTypes: [
        { value: 1, text: "Número de telefone" },
        { value: 2, text: "Nome do convidado" },
        { value: 3, text: "CPF" },
      ],
      headers: [
        { text: "Pedido", value: "order" },
        { text: "Título do Evento", value: "title" },
        { text: "Responsável", value: "name" },
        { text: "Email", value: "email" },
        { text: "Chave de acesso", value: "key" },
        { text: "Data do evento", value: "date" },
        { text: "Texto Cliente", value: "text" },
        { text: "Download Convite", value: "invite" },
        { text: "URL Acompanhamento", value: "urlInvite" },
        { text: "URL Confirmação", value: "urlRSVP" },
        { text: "", value: "actions", sortable: false },
      ],
      events: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        order: "",
        title: "",
        name: "",
        invite: "",
        urlInvite: "",
        urlRSVP: "",
        key: "",
        email: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        termlocal: "",
        attaches: [],
        text: "",
        confirmType: "",
        noGuest: false,
        noAge: true,
        textCard1: "",
        disabled: false,
      },
      defaultItem: {
        id: "",
        order: "",
        title: "",
        name: "",
        invite: "",
        urlInvite: "",
        urlRSVP: "",
        key: "",
        email: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        termlocal: "",
        attaches: [],
        text: "",
        confirmType: "",
        noGuest: false,
        noAge: true,
        textCard1: "",
        disabled: false,
      },
      menu2: false,
    }),

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "Novo evento" : "Editar evento";
      },
      textNoGuest() {
        return this.editedItem.noGuest ? "Sem Acompanhantes" : "Com Acompanhantes";
      },
      textNoAge() {
        return this.editedItem.noAge ? "Sem Idade" : "Com idade";
      },
    },

    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },

    filters: {
      truncate: function (data, num) {
        const reqdString = data.split("").slice(0, num).join("");
        return reqdString + "...";
      },
    },
    methods: {
      editItem(item) {
        this.editedIndex = this.events.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
        this.bannerFile.name = this.editedItem.termlocal;
        this.inviteFile = { name: this.editedItem.invite};
      },

      deleteItem(item) {
        this.editedIndex = this.events.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      save() {
        this.editedItem.termlocal = this.bannerFile.name;
        this.editedItem.invite = this.inviteFile.name.match(/https/) ? this.inviteFile.name : "https://confirmar.animavite.com/invite/"+this.editedItem.id+"/" + this.inviteFile.name;
        if (this.editedIndex > -1) {
          this.updateDB();
        } else {
          this.addDB();
        }
        this.uploadFile(this.bannerFile);
        this.uploadFile(this.inviteFile);

        this.close();
        setTimeout(() => {
          this.loadDB();
        }, 2000);

      },
      addDB() {
        // Create Event
        this.$http.post("events.json", this.editedItem).then((res) => {
          var id = res.request.responseText.trim().replace("{", "").replace("}", "").replace(":", "").replace("name", "").replace("-", "").replace(/"/g, "");
          let rsvp = "https://confirmar.animavite.com/#/rsvp/" + id;
          let invite = "https://confirmar.animavite.com/#/list/" + id;
          let dateTime = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);

          // Create RSVPlink
          this.$http.post("rsvplink.json", { id: id, dateTime: dateTime }).then((res) => {
            let keyLink = res.request.responseText.trim().replace("{", "").replace("}", "").replace(":", "").replace("name", "").replace("-", "").replace(/"/g, "");

            // Update Event adding key
            this.$http.patch("events/-" + id + ".json", {
              id: id,
              urlInvite: invite,
              urlRSVP: rsvp,
              key: keyLink,
            });
          });
        }, this.events.push(this.editedItem));
      },
      updateDB() {
        this.$http.put("events/-" + this.editedItem.id + ".json", this.editedItem).then(Object.assign(this.events[this.editedIndex], this.editedItem));
      },
      uploadFile(file) {
        if (!file.size) return;        
        let formData = new FormData();
        formData.append("file", file);

        this.$http2
          .post("file.php?id=" + this.editedItem.id, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            if (response.data == 0) {
              alert("Erro ao enviar o arquivo " + file.name);
            } else if (response.data == 1) {
              alert("Arquivo " + file.name + " enviada com sucesso");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },

      loadDB() {
        this.events = [];
        // Retrieve
        this.$http.get("events.json").then((res) => {
          this.events = res.data;
          this.events.forEach((e) => {
            e.text = this.greetingTime() + " " + e.name + "!  O *Convite do " + e.title + "* ficou pronto 🎉 \n\n";
            e.text += "💡 Fizemos um *site exclusivo para você* acompanhar a confirmação de presença ou ausência dos seus convidados!!! Lá você pode ver a _lista online_, baixar _relatório em PDF_ ou _acessar o convite_. 🥳\n\n";
            e.text += "✅ *Endereço do Site*: " + e.urlInvite + " \n\n";
            e.text += "🔑 *Código de Acesso*: " + e.key + "\n\n";
            e.text += "⚡ *DICA*: Copie o código de acesso antes de clicar no Link! 😉 ➡️ Para qualquer dúvida me chama aqui no _Whatsapp_.\n\n";
            e.text += "🎉 *Boa Festa*! 🎈 www.animavite.com \n\n";
          });
        });
      },
      greetingTime() {
        var curHr = new Date().getHours();
        if (curHr < 12) return "Bom dia";
        else if (curHr < 18) return "Boa tarde";
        else return "Boa noite";
      },
      removeDB() {
        // Delete
        this.$http.delete(`/rsvplink/-${this.editedItem.key}.json`);
        this.$http.delete(`/events/-${this.editedItem.id}.json`).then(() => {
          this.events.splice(this.editedIndex, 1);
          this.closeDelete();
        });
      },
      copyText() {
        navigator.clipboard.writeText(this.text1);
      },

      login() {
        this.$http
          .get("admin/-" + this.authData + ".json")
          .then((res) => {
            //console.log(res.data);

            this.loggedAdmin = res.data != "" ? true : false;
            this.authData = "";
            if (this.loggedAdmin) {
              this.msgError = false;
              this.loadDB();
            } else {
              this.msgError = true;
              //this.initialize();
              setTimeout(() => {
                this.msgError = false;
              }, 5000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async copy(mytext) {
        try {
          await navigator.clipboard.writeText(mytext);
        } catch ($e) {
          console.log("Clipboard error");
        }
      },
    },
  };
</script>
