<template>
  <v-card flat>
    <v-toolbar color="primary" dark extended flat> </v-toolbar>

    <v-card class="mx-auto" :max-width="this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ? '90vw' : '50vw'" style="margin-top: -64px">
      <v-toolbar dark class="yellow--text center-text text-sm">
        <v-toolbar-title>
          <v-icon>mdi-arrow-right</v-icon>
          <span v-if="!this.$vuetify.breakpoint.xs"> Cadastro para elaboração de convite <strong>(Pedido 0124)</strong> </span>
          <span v-else style="font-size: 0.9em">
            Cadastro
            <strong>(Pedido 0124)</strong>
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-icon>mdi-information</v-icon>
      </v-toolbar>

      <v-progress-linear v-model="progress" height="50" striped color="amber">
        <strong>{{ Math.ceil(progress) }}% completo</strong>
      </v-progress-linear>

      <v-divider></v-divider>
      <v-card-text>
        <v-carousel v-model="model" show-arrows-on-hover height="fit-content" class="pb-10">
          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height ma-auto" align="center" justify="center">
                <v-card :min-width="this.$vuetify.breakpoint.xs ? '100%' : '90%'" elevation="0" :class="'rounded-t-lg ' + this.$vuetify.breakpoint.xs ? 'pa-0' : 'pa-7'">
                  <v-text-field v-model="telefone" v-mask="['(##) ####-####', '(##) #####-####']" full-width hint="Informe o seu número de telefone com Whatsapp para entrarmos em contato contigo" class="text-lg-h5" prepend-icon="mdi-phone" label="Informe um número de telefone para contato"> </v-text-field>
                  <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn class="mb-5" color="primary" @click="model++">Próximo</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height ma-auto" align="center" justify="center">
                <v-card :min-width="this.$vuetify.breakpoint.xs ? '100%' : '90%'" elevation="0" :class="'rounded-t-lg ' + this.$vuetify.breakpoint.xs ? 'pa-0' : 'pa-7'">
                  <v-text-field v-model="nome" full-width hint="O nome não precisa ser completo, insira exatamente como irá aparecer no convite virtual" class="text-lg-h5" prepend-icon="mdi-account" :label="'Insira o nome ' + (this.$vuetify.breakpoint.xs ? 'no convite' : 'como deve aparecer no convite')"> </v-text-field>
                  <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn class="mb-5 ml-2" color="warning" @click="model--">Anterior</v-btn>
                    <v-btn class="mb-5" color="primary" @click="model++">Próximo</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height ma-auto" align="center" justify="center">
                <v-card :min-width="this.$vuetify.breakpoint.xs ? '100%' : '90%'" elevation="0" :class="'rounded-t-lg ' + this.$vuetify.breakpoint.xs ? 'pa-0' : 'pa-7'">
                  <v-text-field type="number" v-model="idade" full-width hint="Insira a idade como irá aparecer no convite virtual" class="text-lg-h5" prepend-icon="mdi-timer-sand" :label="'Insira a idade ' + (this.$vuetify.breakpoint.xs ? 'no convite' : 'como deve aparecer no convite')"> </v-text-field>
                  <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn class="mb-5 ml-2" color="warning" @click="model--">Anterior</v-btn>
                    <v-btn class="mb-5" color="primary" @click="model++">Próximo</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height ma-auto" align="center" justify="center">
                <v-card :min-width="this.$vuetify.breakpoint.xs ? '100%' : '90%'" elevation="0" :class="'rounded-t-lg ' + this.$vuetify.breakpoint.xs ? 'pa-0' : 'pa-7'">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dataFormatada" label="Escolha a data do evento" hint="Selecione no calendário a data que acontecerá o evento" full-width class="text-lg-h5" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker locale="pt-br" v-model="data" @input="menu2 = false" @blur="date = parseDate(dateFormatted)"></v-date-picker>
                  </v-menu>

                  <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn class="mb-5 ml-2" color="warning" @click="model--">Anterior</v-btn>
                    <v-btn class="mb-5" color="primary" @click="model++">Próximo</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height ma-auto" align="center" justify="center">
                <v-card :min-width="this.$vuetify.breakpoint.xs ? '100%' : '90%'" elevation="0" :class="'rounded-t-lg ' + this.$vuetify.breakpoint.xs ? 'pa-0' : 'pa-7'">
                  <v-text-field v-model="local" full-width hint="Insira o endereço do local que acontecerá o evento" class="text-lg-h5" prepend-icon="mdi-timer-sand" label="Local de evento"> </v-text-field>
                  <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn class="mb-5 ml-2" color="warning" @click="model--">Anterior</v-btn>
                    <v-btn class="mb-5" color="primary" @click="model++">Próximo</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height ma-auto" align="center" justify="center">
                <v-card :min-width="this.$vuetify.breakpoint.xs ? '100%' : '90%'" elevation="0" :class="'rounded-t-lg ' + this.$vuetify.breakpoint.xs ? 'pa-0' : 'pa-7'">
                  <gift-boxes></gift-boxes>

                  <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn class="mb-5 ml-2" color="warning" @click="model--">Anterior</v-btn>
                    <v-btn class="mb-5" color="primary" @click="model++">Próximo</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height ma-auto" align="center" justify="center">
                <v-card :min-width="this.$vuetify.breakpoint.xs ? '100%' : '90%'" elevation="0" :class="'rounded-t-lg ' + this.$vuetify.breakpoint.xs ? 'pa-0' : 'pa-7'">
                  <h3>Confirmação dos dados</h3>
                  <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn class="mb-5 ml-2" color="warning" @click="model--">Anterior</v-btn>
                    <v-btn class="mb-5" color="primary" @click="model++">Próximo</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>

          <v-carousel-item>
            <v-sheet>
              <v-row class="fill-height ma-auto" align="center" justify="center">
                <v-card :min-width="this.$vuetify.breakpoint.xs ? '100%' : '90%'" elevation="0" :class="'rounded-t-lg ' + this.$vuetify.breakpoint.xs ? 'pa-0' : 'pa-7'">
                  <h3 class="mb-4">Dados enviados com sucesso</h3>
                  <v-card-text>
                    <p>Seu convite já está em produção! Assim que terminamos enviaremos um notificação por email ou whatsapp.</p>
                    <p>A Animavite agradece sua preferência.</p>
                  </v-card-text>
                  <v-card-actions class="mt-4">
                    <v-spacer></v-spacer>
                    <v-btn class="mb-5 ml-2" color="warning" @click="model--">Anterior</v-btn>
                    <v-btn class="mb-5" color="primary" @click="model++">Próximo</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
  import GiftBoxes from "../components/GiftBoxes.vue";

  export default {
    components: { GiftBoxes },
    data: () => ({
      model: 0,
      menu2: false,
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      organizador: "Elaine Rocha",
      telefone: "",
      nome: "",
      idade: "",
      data: "",
      local: "",
    }),
    computed: {
      progress() {
        return (this.model / 7) * 100;
      },
      dataFormatada() {
        if (!this.data) return null;
        const [year, month, day] = this.data.split("-");
        return `${day}/${month}/${year}`;
      },
    },
  };
</script>

<style>
  .v-input .v-label {
    font-size: 0.9em !important;
  }
</style>
