<template>
  <v-card :max-width="this.breakpoint == 'xs' ? '90vw' : '45vw'" class="mx-auto" min-height="360">
    <v-img class="white--text align-end" height="200px" :src="termLocal ? 'https://confirmar.animavite.com/bg/' + termLocal : 'https://source.unsplash.com/random/?' + term">
      <v-card-title class="subtitle">Confirmar a presença</v-card-title>
    </v-img>
    <v-card-subtitle class="pb-3">Uma grande festa se faz com bons convidados!</v-card-subtitle>
    <v-card-text class="text--primary">{{ this.textCard1 ? this.textCard1 : "Utilize o botão abaixo para confirmar e informar o número de convidados que irá contigo no nosso evento!" }} </v-card-text>
    <v-card-actions>
      <v-dialog v-model="dialog1" persistent max-width="700px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn block color="primary" dark v-bind="attrs" v-on="on">Confirmar</v-btn>
        </template>
        <v-card>
          <v-alert v-if="this.confirmed" class="darken-2" type="success">
            <strong>A sua presença já está confirmada!</strong>
            <div>
              <small>Você pode alterar a confirmação até a data do evento</small>
            </div>
          </v-alert>
          <v-card-title><h4>Confirmação da Presença</h4></v-card-title>
          <v-card-text>
            <v-container>
              <v-alert v-if="this.confirmType == 3" transition="scale-transition" :value="cpfError" border="left" color="red" dense dismissible elevation="4" icon="mdi-account" type="error">Preencha o CPF corretamente.</v-alert>
              <v-row>
                <v-col cols="12" v-if="this.confirmType != 2">
                  <v-text-field v-if="this.confirmType == 1" v-model="guest.cpf" @blur="retrieve()" label="Número do telefone" hide-details="auto" required v-mask="['(##) #####-####']"></v-text-field>
                  <v-text-field v-if="this.confirmType == 3" v-model="guest.cpf" @blur="retrieve()" label="CPF" hide-details="auto" required v-mask="['###.###.###-##']"></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="showName || this.confirmType == 2">
                <v-col :cols="this.noAge || this.breakpoint == 'xs' ? 12 : 8">
                  <v-text-field v-model.lazy="guest.name" label="Nome Completo" :rules="rules" hide-details="auto" required></v-text-field>
                </v-col>
                <v-col v-if="!this.noAge" :cols="this.breakpoint == 'xs' ? 12 : 4">
                  <v-select v-model="guest.age" :items="ages" label="Idade" :rules="rulesOnlyRequired" hide-details="auto" required></v-select>
                </v-col>
              </v-row>
              <v-row v-if="guest.name && !this.noGuest">
                <v-col cols="12">
                  <h4 class="ma-0 mt-4">Quem mais vai contigo?</h4>
                  <v-card-text class="pl-0 mb-0 pt-2" style="font-style: italic">Clique no botão abaixo para adicionar seus acompanhantes</v-card-text>
                  <v-row style="border: 1px solid gray" class="mb-1" v-for="(attach, index) in this.guest.attaches" :key="index">
                    <v-col class="pt-0 pb-0" :cols="noAge || breakpoint == 'xs' ? 11 : 7">
                      <v-text-field v-model="attach.name" :label="'Insira o nome do ' + (index + 1) + 'º acompanhante'"> </v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" v-if="!noAge" :cols="breakpoint == 'xs' ? 12 : 4">
                      <v-select v-model="attach.age" :items="ages" :label="breakpoint != 'xs' ? 'Idade' : 'Idade do ' + (index + 1) + 'º acompanhante'" :rules="rulesOnlyRequired" required></v-select>
                    </v-col>
                    <v-col cols="1" :style="breakpoint == 'xs' ? 'min-width:100%;text-align:center' : ''">
                      <v-btn class="pt-0 pb-0" v-if="breakpoint == 'xs'" @click="deleteAttach(index)" x-small>Remover {{ index + 1 + "º acompanhante" }}</v-btn>
                      <v-icon class="center" v-else @click="deleteAttach(index)" slot="append" color="error">mdi-minus</v-icon>
                    </v-col>
                  </v-row>
                  <v-btn class="mt-10" tile color="primary" @click="addAttach()"><v-icon left> mdi-account-multiple-plus </v-icon> Adicionar acompanhante</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="warning" @click="dialog1 = false">Fechar </v-btn>
            <v-btn v-if="noAge" depressed color="success" @click="save()" :disabled="guest.name.length < 3">Confirmar a presença</v-btn>
            <v-btn v-else depressed color="success" @click="save()" :disabled="disableButtonWithAge">Confirmar a presença</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
    <v-dialog v-model="thankyou" max-width="400">
      <v-img style="width: 400px; height: 400px" src="../assets/thankyou.gif"></v-img>
    </v-dialog>
  </v-card>
</template>

<script>
  export default {
    props: {
      id: String,
      term: String,
      termLocal: String,
      noGuest: Boolean,
      noAge: Boolean,
      textCard1: String,
      confirmType: { type: Number, default: 1 },
    },
    data: () => ({
      guest: {
        name: "",
        cpf: "",
        msg: "",
        accept: true,
        attaches: [],
        age: "",
      },
      ages: [],
      confirmed: false,
      dialog1: false,
      thankyou: false,
      rules: [(value) => !!value || "Campo obrigatório.", (value) => (value && value.length >= 3) || "Minínimo 3 caracteres"],
      rulesOnlyRequired: [(value) => !!value || "Campo obrigatório.", (value) => (value && value != "") || "Campo obrigatório."],
      breakpoint: "",
    }),
    mounted() {
      this.ages.push({ text: "Menos que 1", value: "0" });
      this.ages.push({ text: "1 ano", value: "1" });
      for (let i = 2; i <= 14; i++) this.ages.push({ text: i + " anos", value: i });
      this.ages.push({ text: "Adolescente/Adulto", value: "Adolescente/Adulto" });
      this.breakpoint = this.$vuetify.breakpoint.name;
    },
    methods: {
      save: function () {
        this.dialog1 = false;
        if(this.confirmType == 2) this.guest.cpf = Math.floor(Date.now() / 1000) + "";
        if (this.guest.cpf) {
          let guestId = this.guest.cpf.trim().replace(/\./g, "").replace(/-/g, "").replace("(", "").replace(")", "").replace(" ", "");
          this.$http.patch("events/-" + this.id + "/guests/" + guestId + ".json", this.guest).then((res) => {
            if (res.data && res.data.accept) {
              this.thankyou = true;
              setTimeout(() => {
                this.thankyou = false;
              }, 5000);
              this.clean();
            }
          });
        }
      },
      addAttach: function () {
        if (this.guest.attaches == null) this.guest.attaches = [];
        if (this.noAge) this.guest.attaches.push({ name: "" });
        else this.guest.attaches.push({ name: "", age: "" });
      },
      deleteAttach: function (index) {
        this.guest.attaches.splice(index, 1);
      },
      retrieve: function () {
        if (this.guest.cpf && (this.checkCPF() || this.confirmType != 3)) {
          let guestId = this.guest.cpf.replace(/\./g, "").replace(/-/, "").replace("(", "").replace(")", "").replace(" ", "").trim();
          this.$http.get("events/-" + this.id + "/guests/" + guestId + ".json").then((res) => {
            if (res.data && res.data.accept) {
              //this.guest = { ...res.data };
              this.guest.name = res.data.name;
              this.guest.cpf = res.data.cpf;
              this.guest.msg = res.data.msg;
              this.guest.accept = res.data.accept;
              if (res.data.attaches) this.guest.attaches = res.data.attaches;
              if (res.data.age) this.guest.age = res.data.age;
              this.confirmed = true;
            }
          });
        }
      },
      clean: function () {
        (this.guest.name = ""), (this.guest.cpf = ""), (this.guest.msg = ""), (this.guest.accept = true), (this.guest.attaches = []);
      },
      checkCPF: function () {
        if (this.guest.cpf.length != 14) return false;
        var soma = 0;
        var i;
        var resto;
        let inputCPF = this.guest.cpf.replace(/\./g, "").replace("-", "");

        if (inputCPF == "00000000000") return false;
        for (i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(inputCPF.substring(9, 10))) return false;

        soma = 0;
        for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto == 10 || resto == 11) resto = 0;
        if (resto != parseInt(inputCPF.substring(10, 11))) return false;

        return true;
      },
    },
    watch: {
      guest: function () {
        if (!this.guest.cpf) this.confirmed = false;
      },
    },
    computed: {
      showName: function () {
        return (this.confirmType == 3 && this.checkCPF()) || (this.confirmType == 1 && this.guest.cpf.length == 15);
      },
      cpfError: function () {
        return (this.confirmType == 3 && this.guest.cpf != "" && this.guest.cpf.length == 14 && !this.checkCPF()) || (this.confirmType == 1 && this.guest.cpf.length == 15);
      },
      disableButtonWithAge: function () {
        let disable = false;
        if (this.guest.name.length < 3 || this.guest.age == "") disable = true;
        if (this.guest.attaches.length > 0)
          this.guest.attaches.forEach((element) => {
            console.log(element);
            if (element.age == "") disable = true;
          });
        return disable;
      },
    },
  };
</script>
<style scoped>
  .subtitle {
    font-size: 2.75rem;
  }
  h4 {
    font-size: 2rem;
  }
  .center {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
</style>
